import React, { Component, useState, useEffect } from 'react';
import './step2.css';
import Countdown from '../countdown';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { URL, spriteKey } from './../../config';
import CalendarIcon from './../../assets/images/icon-feather-calendar.svg';
import TimeIcon from './../../assets/images/icon-material-access-time.svg';
import PeopleIcon from './../../assets/images/icon-material-people-outline.svg';
import { BsCash } from 'react-icons/bs';
import logo from './../../assets/images/logo.png';
import { formatDate } from 'react-day-picker/moment';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { withStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { FaUserGroup } from 'react-icons/fa6';
import { FaCalendarCheck } from 'react-icons/fa';
import { MdOutlineAccessTimeFilled } from 'react-icons/md';
import { MdChair } from 'react-icons/md';
import TicketBookingForm from './../payment/TicketBookingForm';
const styles = (theme) => ({
  root: {
    display: 'block'
  },
  formControl: {
    minWidth: 120
  }
});
const stripe = {};
const elements = {};
const stripePromise = loadStripe(spriteKey);
class Step2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: this.props.match.params.key,
      title: document.getElementById('bookingWidgetRoot').getAttribute('data-title'),
      bookingId: this.props.match.params.booking_id,
      createdAt: null,
      formId: 'updateBooking',
      bookingDate: '',
      partySize: '',
      floorName: '',
      bookingTime: 0,
      time: '',
      experience_id: 0,
      experience_name: '',
      stripe: null,
      elements: null,
      dialogName: '',
      price: 0,
      widgetNoteTitle: '',
      widgetNoteMessage: '',
      onlinePaymentFee: null,
      loading: false,
      openDialog: false,
      dialogTitle: '',
      businessName: '',
      floorName: '',
      chooseFloor: false,
      displaySection: false,
      api: null
    };
    this.newLineMessage = this.newLineMessage.bind(this);
  }
  intialValues = () => {
    return {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      request: ''
    };
  };
  handleClickOpen = (title) => {
    // setOpen(true);
    this.setState({
      openDialog: true,
      dialogTitle: title
    });
  };
  handleClose = () => {
    // setOpen(false);
    this.setState({
      openDialog: false
    });
  };
  validationShema = () => {
    return Yup.object().shape({
      firstName: Yup.string().required('First Name is required'),
      lastName: Yup.string().required('Last Name is required'),
      email: Yup.string().email('Email is invalid'),
      phoneNumber: Yup.string().required('Phone Number is required')
    });
  };
  handleSubmit = (fields) => {
    const data = {
      first_name: fields.firstName,
      last_name: fields.lastName,
      phone_number: fields.phoneNumber,
      email: fields.email,
      request: fields.request
    };
    axios({
      method: 'POST',
      url: `${URL}/${this.state.api}/booking/${this.state.bookingId}/confirm`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.state.key}`
      },
      data: data
    })
      .then((response) => {
        let responseData = response.data.data;
        const bookingData = {
          date: formatDate(responseData.booking.start_date, 'dddd, Do MMM'),
          time: this.state.time,
          partySize: this.state.partySize,
          createdAt: responseData.booking.created_at,
          appleWalletUrl: responseData.appleWalletUrl,
          googleWalletUrl: responseData.googleWalletUrl,
          downloadId: responseData.downloadId,
          floorName: this.state.floorName,
          chooseFloor: this.state.chooseFloor,
          displaySection: this.state.displaySection,
          api: this.state.api,
          path: this.state.api === 'widget' ? '/' : '/v2/'
        };
        this.props.history.push({
          pathname: `/step3/${this.state.key}`,
          bookingData: bookingData
        });
      })
      .catch((error) => {
        this.setState({
          loading: false
        });
        if (error.response) {
          let message = error.response.data.message;
          this.handleClickOpen(message);
        } else {
          this.handleClickOpen(error);
        }
      });
  };
  componentWillMount() {
    if (this.props.location.ticketBookingData !== undefined) {
      this.setState({
        bookingDate: this.props.location.ticketBookingData.date,
        time: this.props.location.ticketBookingData.time,
        countByName: this.props.location.ticketBookingData.countByName,
        createdAt: this.props.location.ticketBookingData.createdAt,
        ticket_id: this.props.location.ticketBookingData.ticket_id,
        ticket_name: this.props.location.ticketBookingData.ticket_name,
        price: this.props.location.ticketBookingData.price,
        stripe: window.Stripe(spriteKey),
        businessName: this.props.location.ticketBookingData.businessName,
        onlinePaymentFee: this.props.location.ticketBookingData.onlinePaymentFee
      });
    } else if (this.props.location.bookingData !== undefined) {
      this.setState({
        bookingDate: this.props.location.bookingData.date,
        partySize: this.props.location.bookingData.partySize,
        time: this.props.location.bookingData.time,
        createdAt: this.props.location.bookingData.createdAt,
        floorName: this.props.location.bookingData.floor_name,
        experience_id: this.props.location.bookingData.experience_id ?? 0,
        experience_name: this.props.location.bookingData.experience_name ?? '',
        stripe: window.Stripe(spriteKey),
        shift_id: this.props.location.bookingData.shift_id,
        price: this.props.location.bookingData.price,
        widgetNoteTitle: this.props.location.bookingData.widgetNoteTitle,
        widgetNoteMessage: this.props.location.bookingData.widgetNoteMessage,
        onlinePaymentFee: this.props.location.bookingData.onlinePaymentFee,
        businessName: this.props.location.bookingData.businessName,
        chooseFloor: this.props.location.bookingData.chooseFloor,
        displaySection: this.props.location.bookingData.displaySection,
        api: this.props.location.bookingData.api || 'widget/v2'
      });
    } else {
      this.props.history.goBack();
    }
  }
  newLineMessage = (text) => {
    if (text === null || text === '' || text === undefined) {
      return '';
    }
    const txt = text;
    const newText = txt.split('\n').map((str, index) => <div key={index}>{str}</div>);
    return newText;
  };
  loadBookingDetails = () => {
    axios({
      method: 'GET',
      url: `${URL}/widget/booking/${this.state.bookingId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.state.key}`
      }
    }).then((response) => {
      let responseData = response.data.data;
      let booking = responseData.booking;
      this.setState({
        bookingDate: formatDate(booking.start_date, 'dddd, D MMM'),
        partySize: booking.party_size,
        time: formatDate(booking.start_date, 'LT'),
        createdAt: booking.created_at
      });
    });
  };
  render() {
    const { classes } = this.props;
    let timeTillDate = 120;
    const experience_id = this.state.experience_id;
    const widgetNoteTitle = this.state.widgetNoteTitle;
    const widgetNoteMessage = this.state.widgetNoteMessage;
    const online_pay = this.state.onlinePaymentFee !== null && this.state.onlinePaymentFee > 0;
    const key = this.state.key;
    const bookingId = this.state.bookingId;

    if (this.state.ticket_id !== undefined) {
      // ticket booking
      return (
        <Elements stripe={stripePromise}>
          <TicketBookingForm
            date={this.state.bookingDate}
            time={this.state.time}
            countByName={this.state.countByName}
            createdAt={this.state.createdAt}
            ticket_id={this.state.ticket_id}
            ticket_name={this.state.ticket_name}
            price={this.state.price}
            businessName={this.state.businessName}
            onlinePaymentFee={this.state.onlinePaymentFee}
            property={this.props}
            uuid={bookingId}
            bearer={key}
            path={this.state.api === 'widget' ? '/' : '/v2/'}
          />
        </Elements>
      );
    } else if (experience_id == 0 && online_pay == false) {
      // normal booking without online payment
      return (
        <div className="booking_widget__app step2">
          <div className={classes.root}>
            <h1
              className="mb-3"
              style={{
                fontSize: '32px'
              }}
            >
              Reservation at {this.state.businessName}
            </h1>
            {/* <p> Kindly complete the form</p> */}
            <Countdown secondsRemaining={timeTillDate} />
            <div className="">
              <div className="mb-5">
                <div>
                  <div className="info-container rounded-lg mb-5">
                    {/* <h2>{this.state.title}</h2> */}
                    <div className="icons">
                      <FaCalendarCheck className="mr-2" />
                      <span style={{ fontSize: '14px' }}>{this.state.bookingDate}</span>
                    </div>
                    <div className="icons small-font">
                      <MdOutlineAccessTimeFilled className="mr-2" />
                      <span style={{ fontSize: '14px' }}>{this.state.time}</span>
                    </div>
                    <div className="icons small-font">
                      <FaUserGroup className="mr-2" />
                      <span style={{ fontSize: '14px' }}>{this.state.partySize} guests</span>
                    </div>
                    {(this.state.chooseFloor || this.state.displaySection) && (
                      <div className="icons small-font">
                        <MdChair className="mr-2" />
                        <span style={{ fontSize: '14px' }}>Floor: {this.state.floorName}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <Formik
                    initialValues={this.intialValues()}
                    validationSchema={this.validationShema()}
                    onSubmit={async (fields) => {
                      if (this.state.loading === false) {
                        this.setState({
                          loading: true
                        });
                        await new Promise((r) => setTimeout(r, 500));
                        this.handleSubmit(fields);
                      }
                    }}
                    render={({ errors, status, touched }) => (
                      <Form id={this.state.formId}>
                        <div className="form-group">
                          <label style={{ fontSize: '14px', marginLeft: '5px' }}>First Name</label>
                          <Field
                            style={{
                              display: 'block',
                              background: 'rgb(255, 255, 255)',
                              borderRadius: 10,
                              boxShadow: 'rgb(0 0 0 / 16%) 0px 2px 6px',
                              border: 'none',
                              color: '#333333',
                              fontSize: 12
                            }}
                            placeholder="First Name"
                            name="firstName"
                            type="text"
                            className={
                              'rounded-lg form-control' +
                              (errors.firstName && touched.firstName ? ' is-invalid' : '')
                            }
                          />
                          <ErrorMessage
                            name="firstName"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group">
                          <label style={{ fontSize: '14px', marginLeft: '5px' }}>Last Name</label>
                          <Field
                            style={{
                              display: 'block',
                              background: 'rgb(255, 255, 255)',
                              borderRadius: 10,
                              boxShadow: 'rgb(0 0 0 / 16%) 0px 2px 6px',
                              border: 'none',
                              fontSize: 12,
                              color: '#333333'
                            }}
                            placeholder="Last Name"
                            name="lastName"
                            type="text"
                            className={
                              'rounded-lg form-control' +
                              (errors.lastName && touched.lastName ? ' is-invalid' : '')
                            }
                          />
                          <ErrorMessage
                            name="lastName"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group">
                          <label style={{ fontSize: '14px', marginLeft: '5px' }}>
                            Phone Number
                          </label>
                          <Field
                            style={{
                              display: 'block',
                              background: 'rgb(255, 255, 255)',
                              borderRadius: 10,
                              boxShadow: 'rgb(0 0 0 / 16%) 0px 2px 6px',
                              border: 'none',
                              fontSize: 12,
                              color: '#333333'
                            }}
                            placeholder="Phone Number"
                            name="phoneNumber"
                            type="text"
                            className={
                              'rounded-lg form-control' +
                              (errors.phoneNumber && touched.phoneNumber ? ' is-invalid' : '')
                            }
                          />
                          <ErrorMessage
                            name="phoneNumber"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group">
                          <label style={{ fontSize: '14px', marginLeft: '5px' }}>Email</label>
                          <Field
                            style={{
                              display: 'block',
                              background: 'rgb(255, 255, 255)',
                              borderRadius: 10,
                              boxShadow: 'rgb(0 0 0 / 16%) 0px 2px 6px',
                              border: 'none',
                              fontSize: 12,
                              color: '#333333'
                            }}
                            placeholder="Email"
                            name="email"
                            type="text"
                            className={
                              'rounded-lg form-control' +
                              (errors.email && touched.email ? ' is-invalid' : '')
                            }
                          />
                          <ErrorMessage name="email" component="div" className="invalid-feedback" />
                        </div>
                        <div className="form-group">
                          <label style={{ fontSize: '14px', marginLeft: '5px' }}>Request</label>
                          <Field
                            style={{
                              display: 'block',
                              background: 'rgb(255, 255, 255)',
                              borderRadius: 10,
                              boxShadow: 'rgb(0 0 0 / 16%) 0px 2px 6px',
                              border: 'none',
                              fontSize: 12,
                              color: '#333333'
                            }}
                            placeholder="Request"
                            name="request"
                            type="text"
                            className={
                              'rounded-lg form-control' +
                              (errors.request && touched.request ? ' is-invalid' : '')
                            }
                          />
                          <ErrorMessage
                            name="request"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </Form>
                    )}
                  />
                </div>
              </div>
              {(widgetNoteTitle !== null && widgetNoteMessage !== null) ||
              (widgetNoteTitle !== '' && widgetNoteMessage !== '') ? (
                <div>
                  <div>
                    <h2 className="afterHrh2"> {widgetNoteTitle} </h2>
                  </div>
                  <div>
                    <p>{this.newLineMessage(widgetNoteMessage)}</p>
                  </div>
                </div>
              ) : (
                <div className="px-2">
                  <div>
                    <h2 className=""> What to know before you go </h2>
                  </div>
                  <div>
                    <h3>Important dining information</h3>
                    <p>
                      We have a 15 minute grace period. Please call us if you are running later than
                      15 minutes after your reservation time.
                    </p>
                  </div>
                  <div>
                    <h3>We may contact you about this</h3>
                    <p>
                      reservation, so please ensure your email and phone number are up to date...
                    </p>
                  </div>
                  <div>
                    <h3>A note from the restaurant</h3>
                    <p>
                      If you would like a rooftop table for dining, please specify - once all tables
                      on the roof have been reserved bookings will be allocated indoors. Please call
                      if you have any concerns. All diners have the table for a maximum of 2 hours.
                    </p>
                  </div>
                </div>
              )}
              <div className="text-center">
                <Button
                  form={this.state.formId}
                  variant="contained"
                  style={{
                    background: '#222a2f',
                    textTransform: 'none',
                    width: '100%'
                  }}
                  type="submit"
                  className="submit text-white rounded-lg mb-4"
                >
                  {this.state.loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    'Confirm Booking'
                  )}
                </Button>
              </div>
              <Row>
                <Col xs lg="12" className="text-center">
                  <p className="footer">
                    "Confirm Reservation" you are agreeing to the&nbsp;
                    <a
                      href="https://wabify.com/terms-and-conditions"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      terms and conditions
                    </a>{' '}
                    of the{' '}
                    <a
                      href="https://wabify.com/privacy-policy"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      WABI User Agreement
                    </a>
                  </p>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col xs lg="12" className="text-center">
                  <p className="booking_widget__powered_by">
                    Powered by{' '}
                    <a href="https://wabify.com" rel="noopener noreferrer" target="_blank">
                      <img src={logo} alt="logo" width={70} height={30} />
                    </a>
                  </p>
                </Col>
              </Row>
            </div>
            <Dialog
              open={this.state.openDialog}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{`Error`}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <strong>{`${this.state.dialogTitle}`}</strong>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  Okay
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      );
    } else if (online_pay == true) {
      // normal booking with online payment
      return (
        <Elements stripe={stripePromise}>
          <BookingPayment
            businessName={this.state.businessName}
            bookingDate={this.state.bookingDate}
            time={this.state.time}
            partySize={this.state.partySize}
            floorName={this.state.floorName}
            displaySection={this.state.displaySection}
            chooseFloor={this.state.chooseFloor}
            userKey={this.state.key}
            bookingId={this.state.bookingId}
            property={this.props}
            shiftId={this.state.shift_id}
            price={this.state.price}
            timeTillDate={timeTillDate}
            widgetNoteTitle={this.state.widgetNoteTitle}
            widgetNoteMessage={this.state.widgetNoteMessage}
            onlinePaymentFee={this.state.onlinePaymentFee}
            classes
            api={this.state.api}
          />
        </Elements>
      );
    } else {
      // experience booking
      return (
        <Elements stripe={stripePromise}>
          <CheckoutForm
            experience_id={experience_id}
            title={this.state.experience_name}
            bookingDate={this.state.bookingDate}
            time={this.state.time}
            partySize={this.state.partySize}
            floorName={this.state.floorName}
            formId={this.state.formId}
            userKey={this.state.key}
            bookingId={this.state.bookingId}
            property={this.props}
            shiftId={this.state.shift_id}
            price={this.state.price}
            widgetNoteTitle={widgetNoteTitle}
            widgetNoteMessage={widgetNoteMessage}
          />
        </Elements>
      );
    }
  }
}
const BookingPayment = (props) => {
  const intialValues = () => {
    return {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      request: ''
    };
  };
  const [loading, setLoading] = useState(false);
  const validationShema = () => {
    return Yup.object().shape({
      firstName: Yup.string().required('First Name is required'),
      lastName: Yup.string().required('Last Name is required'),
      email: Yup.string().email('Email is invalid').required('Email is required'),
      phoneNumber: Yup.string().required('Phone Number is required')
    });
  };
  const newLineMessage = (text) => {
    if (text === null || text === '' || text === undefined) {
      return '';
    }
    const txt = text;
    const newText = txt.split('\n').map((str) => <p>{str}</p>);
    return newText;
  };
  const stripe = useStripe();
  const elements = useElements();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const displayAlertMessage = (message) => {
    setMessage(message);
    handleClickOpen();
  };
  const handleSubmit = async (fields) => {
    setLoading(true);
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    const { error, token } = await stripe.createToken(cardElement);
    if (error) {
      console.log('[error]', error);
      setLoading(false);
      displayAlertMessage(error.message);
    } else {
      const clientData = {
        id: props.bookingId,
        stripe: token.id,
        card: token.card,
        first_name: fields.firstName,
        last_name: fields.lastName,
        phone_number: fields.phoneNumber,
        email: fields.email,
        request: fields.request
      };

      axios
        .post(`${URL}/widget/booking/pay`, clientData, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${props.userKey}`
          }
        })
        .then((response) => {
          let responseData = response.data.data;

          const bookingData = {
            date: formatDate(responseData.booking.start_date, 'dddd, Do MMM'),
            time: props.time,
            partySize: props.partySize,
            createdAt: responseData.booking.created_at,
            api: props.api,
            path: props.api === 'widget' ? '/' : '/v2/'
          };

          props.property.history.push({
            pathname: `/step3/${props.userKey}`,
            bookingData: bookingData
          });
        })
        .catch((error) => {
          setLoading(false);

          if (error.response) {
            let status = error.response.status;
            let message = error.response.data.message;
            if (status == 402) {
              displayAlertMessage(message);
            }
          }
        });
    }
  };
  return (
    <div className="booking_widget__app step2">
      <div className={props.classes.root}>
        <h1>Reservation at {props.businessName}</h1>
        <Countdown secondsRemaining={props.timeTillDate} />
        <Container>
          <div>
            <div className="mb-5">
              <div className="info-container rounded-lg mb-5">
                <div className="icons">
                  <FaCalendarCheck className="mr-2" />
                  <span style={{ fontSize: '14px' }}>{props.bookingDate}</span>
                </div>
                <div className="icons small-font">
                  <MdOutlineAccessTimeFilled className="mr-2" />
                  <span style={{ fontSize: '14px' }}>{props.time}</span>
                </div>
                <div className="icons small-font">
                  <FaUserGroup className="mr-2" />
                  <span style={{ fontSize: '14px' }}>{props.partySize} guests</span>
                </div>
                {(props.chooseFloor || props.displaySection) && (
                  <div className="icons small-font">
                    <MdChair className="mr-2" />
                    <span style={{ fontSize: '14px' }}>Floor: {props.floorName}</span>
                  </div>
                )}
                {props.onlinePaymentFee > 0 && (
                  <div className="icons small-font">
                    <BsCash className="mr-2" />
                    <span style={{ fontSize: '14px' }}>Booking Fee: ${props.onlinePaymentFee}</span>
                  </div>
                )}
              </div>
            </div>
            <div>
              <Formik
                initialValues={intialValues()}
                validationSchema={validationShema()}
                onSubmit={async (fields) => {
                  handleSubmit(fields);
                }}
                render={({ errors, status, touched }) => (
                  <Form id="booking_payment">
                    <div className="form-group">
                      <Field
                        style={{
                          display: 'block',
                          background: 'rgb(255, 255, 255)',
                          borderRadius: 10,
                          boxShadow: 'rgb(0 0 0 / 16%) 0px 3px 6px',
                          border: 'none',
                          fontSize: 12,
                          color: '#222a2f'
                        }}
                        placeholder="First Name"
                        name="firstName"
                        type="text"
                        className={
                          'form-control' +
                          (errors.firstName && touched.firstName ? ' is-invalid' : '')
                        }
                      />
                      <ErrorMessage name="firstName" component="div" className="invalid-feedback" />
                    </div>
                    <div className="form-group">
                      <Field
                        style={{
                          display: 'block',
                          background: 'rgb(255, 255, 255)',
                          borderRadius: 10,
                          boxShadow: 'rgb(0 0 0 / 16%) 0px 3px 6px',
                          border: 'none',
                          fontSize: 12,
                          color: '#333333'
                        }}
                        placeholder="Last Name"
                        name="lastName"
                        type="text"
                        className={
                          'form-control' +
                          (errors.lastName && touched.lastName ? ' is-invalid' : '')
                        }
                      />
                      <ErrorMessage name="lastName" component="div" className="invalid-feedback" />
                    </div>
                    <div className="form-group">
                      <Field
                        style={{
                          display: 'block',
                          background: 'rgb(255, 255, 255)',
                          borderRadius: 10,
                          boxShadow: 'rgb(0 0 0 / 16%) 0px 3px 6px',
                          border: 'none',
                          fontSize: 12,
                          color: '#333333'
                        }}
                        placeholder="Phone Number"
                        name="phoneNumber"
                        type="text"
                        className={
                          'form-control' +
                          (errors.phoneNumber && touched.phoneNumber ? ' is-invalid' : '')
                        }
                      />
                      <ErrorMessage
                        name="phoneNumber"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="form-group">
                      <Field
                        style={{
                          display: 'block',
                          background: 'rgb(255, 255, 255)',
                          borderRadius: 10,
                          boxShadow: 'rgb(0 0 0 / 16%) 0px 3px 6px',
                          border: 'none',
                          fontSize: 12,
                          color: '#333333'
                        }}
                        placeholder="Email"
                        name="email"
                        type="text"
                        className={
                          'form-control' + (errors.email && touched.email ? ' is-invalid' : '')
                        }
                      />
                      <ErrorMessage name="email" component="div" className="invalid-feedback" />
                    </div>
                    <div className="form-group">
                      <Field
                        style={{
                          display: 'block',
                          background: 'rgb(255, 255, 255)',
                          borderRadius: 10,
                          boxShadow: 'rgb(0 0 0 / 16%) 0px 3px 6px',
                          border: 'none',
                          fontSize: 12,
                          color: '#333333'
                        }}
                        placeholder="Request"
                        name="request"
                        type="text"
                        className={
                          'form-control' + (errors.request && touched.request ? ' is-invalid' : '')
                        }
                      />
                      <ErrorMessage name="request" component="div" className="invalid-feedback" />
                    </div>
                    <div
                      style={{
                        padding: 18,
                        height: 50,
                        fontSize: 16,
                        borderRadius: 10,
                        background: '#FFFFFF 0% 0% no-repeat padding-box',
                        boxShadow: 'rgb(0 0 0 / 16%) 0px 3px 6px'
                      }}
                    >
                      <CardElement
                        options={{
                          hidePostalCode: true,
                          style: {
                            base: {
                              fontSize: '12px',
                              color: '#707070',
                              height: '80px',
                              '::placeholder': {
                                color: '#B3B3B3'
                              },
                              border: '1px solid #CCCCCC',
                              borderRadius: '10px'
                            },
                            invalid: {
                              color: '#9e2146'
                            }
                          }
                        }}
                      />
                    </div>
                  </Form>
                )}
              />
            </div>
          </div>
          <hr />
          {(props.widgetNoteTitle !== null && props.widgetNoteMessage !== null) ||
          (props.widgetNoteTitle !== '' && props.widgetNoteMessage !== '') ? (
            <Row>
              <Col xs="12" lg="12">
                <h2 className="afterHrh2"> {props.widgetNoteTitle} </h2>
              </Col>
              <Col xs="12" lg="12">
                <div>{newLineMessage(props.widgetNoteMessage)}</div>
              </Col>
            </Row>
          ) : (
            <div className="px-2">
              <div>
                <h2 className="afterHrh2"> What to know before you go </h2>
              </div>
              <div>
                <h3>Important dining information</h3>
                <p>
                  We have a 15 minute grace period. Please call us if you are running later than 15
                  minutes after your reservation time.
                </p>
              </div>
              <div>
                <h3>We may contact you about this</h3>
                <p>reservation, so please ensure your email and phone number are up to date...</p>
              </div>
              <div>
                <h3>A note from the restaurant</h3>
                <p>
                  If you would like a rooftop table for dining, please specify - once all tables on
                  the roof have been reserved bookings will be allocated indoors. Please call if you
                  have any concerns. All diners have the table for a maximum of 2 hours.
                </p>
              </div>
            </div>
          )}
          <Row>
            <Col xs="12" lg="12" className="text-center">
              <Button
                form="booking_payment"
                variant="contained"
                type="submit"
                disabled={loading}
                style={{
                  background: '#222a2f',
                  textTransform: 'none',
                  width: '100%'
                }}
                className="submit text-white rounded-lg mb-4"
              >
                {/* Button when Booking fee is  */}
                {loading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  'Confirm Booking'
                )}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs lg="12" className="text-center">
              <p className="footer">
                "Confirm Reservation" you are agreeing to the&nbsp;
                <a
                  href="https://wabify.com/terms-and-conditions"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  terms and conditions
                </a>{' '}
                of the{' '}
                <a
                  href="https://wabify.com/privacy-policy"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  WABI User Agreement
                </a>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs lg="12" className="text-center">
              <p className="booking_widget__powered_by">
                Powered by{' '}
                <a href="https://wabify.com" rel="noopener noreferrer" target="_blank">
                  <img src={logo} alt="logo" />
                </a>
              </p>
            </Col>
          </Row>
        </Container>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {/* <DialogTitle id="alert-dialog-title">Ticket sold out</DialogTitle> */}
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{`${message}`}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Okay
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};
const CheckoutForm = (props) => {
  const styles = (theme) => ({
    root: {
      display: 'block'
    },
    formControl: {
      minWidth: 120
    }
  });
  const [loading, setLoading] = useState(false);
  const {
    classes,
    experience_id,
    title,
    bookingDate,
    time,
    partySize,
    floorName,
    formId,
    userKey,
    bookingId,
    property,
    shiftId,
    price,
    widgetNoteMessage,
    widgetNoteTitle
  } = props;
  let timeTillDate = 300;
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const displayAlertMessage = (message) => {
    setMessage(message);
    handleClickOpen();
  };
  const stripe = useStripe();
  const elements = useElements();
  const { key, booking_id } = useParams();
  const handleSubmit = async (fields) => {
    setLoading(true);
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);
    // Use your card Element with other Stripe.js APIs
    // const {error, paymentMethod} = await stripe.createPaymentMethod({
    //     type: 'card',
    //     card: cardElement,
    // });
    const { error, token } = await stripe.createToken(cardElement);
    if (error) {
      console.log('[error]', error);
      setLoading(false);
      displayAlertMessage(error.message);
    } else {
      const clientData = {
        first_name: fields.firstName,
        last_name: fields.lastName,
        phone_number: fields.phoneNumber,
        email: fields.email,
        request: fields.request
      };
      axios({
        method: 'GET',
        url: `${URL}/widget/client?first_name=${fields.firstName}&last_name=${fields.lastName}&phone_number=${fields.phoneNumber}&email=${fields.email}&request=${fields.request}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${key}`
        }
      })
        .then((response) => {
          let guest = response.data.data.guest;
          // Do the payment first
          const _data = {
            experience_id: experience_id,
            shift_id: shiftId,
            guest_id: guest.id,
            token: token.id,
            card: token.card,
            no_of_tickets: partySize
          };
          axios
            .post(`${URL}/widget/experience/pay`, _data, {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${key}`
              }
            })
            .then((response) => {
              let payment = response.data.data;
              const data = {
                first_name: fields.firstName,
                last_name: fields.lastName,
                phone_number: fields.phoneNumber,
                email: fields.email,
                request: fields.request
              };
              axios({
                method: 'POST',
                url: `${URL}/${this.state.api}/booking/${booking_id}/confirm`,
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${key}`
                },
                data: data
              })
                .then((response) => {
                  let updateBooking = response.data.data;
                  const bookingData = {
                    date: formatDate(updateBooking.booking.start_date, 'dddd, Do MMM'),
                    time: time,
                    partySize: partySize,
                    createdAt: updateBooking.booking.created_at,
                    guest_id: updateBooking.booking.guest.id,
                    token: token.id,
                    title: title,
                    appleWalletUrl: updateBooking.appleWalletUrl,
                    googleWalletUrl: updateBooking.googleWalletUrl,
                    downloadId: updateBooking.downloadId,
                    api: this.state.api,
                    path: this.state.api === 'widget' ? '/' : '/v2/'
                  };
                  property.history.push({
                    pathname: `/step3/${key}`,
                    bookingData: bookingData,
                    payment: payment
                  });
                  // sendPayment(bookingData)
                })
                .catch((error) => {
                  this.setState({
                    loading: false
                  });
                  if (error.response) {
                    let message = error.response.data.message;
                    this.handleClickOpen(message);
                  } else {
                    this.handleClickOpen(error);
                  }
                });
            })
            .catch((error) => {
              setLoading(false);
              // alert(`${error}`);
              if (error.response) {
                let status = error.response.data.status;
                let message = error.response.data.message;
                if (status == 402) {
                  // alert(`${message}`)
                  displayAlertMessage(message);
                }
              }
            });
        })
        .catch((error) => {
          setLoading(false);
          if (error.response) {
            let message = error.response.data.message;
            displayAlertMessage(message);
          }
        });
    }
  };
  const intialValues = () => {
    return {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      request: ''
    };
  };
  const validationShema = () => {
    return Yup.object().shape({
      firstName: Yup.string().required('First Name is required'),
      lastName: Yup.string().required('Last Name is required'),
      email: Yup.string().email('Email is invalid'),
      phoneNumber: Yup.string().required('Phone Number is required')
    });
  };
  // const experience_id = this.state.experience_id
  const newLineMessage = (text) => {
    if (text === null || text === '' || text === undefined) {
      return '';
    }
    const txt = text;
    const newText = txt.split('\n').map((str) => <p>{str}</p>);
    return newText;
  };
  return (
    <div className="booking_widget__app step2">
      <div className={styles.root}>
        <h1>{title}</h1>
        <Countdown secondsRemaining={timeTillDate} />
        <Container>
          <div>
            <div>
              <div className="info-container experience">
                {/* <h2>{title}</h2> */}
                <div className="icons">
                  <img src={CalendarIcon} alt="logo" />
                  <span style={{ fontSize: '14px' }}>{bookingDate}</span>
                </div>
                <div className="icons">
                  <img src={TimeIcon} alt="logo" />
                  <span style={{ fontSize: '14px' }}>{time}</span>
                </div>
                <div className="icons">
                  <img src={PeopleIcon} alt="logo" />
                  <span style={{ fontSize: '14px' }}>Floor: {floorName}</span>
                </div>
                <div className="icons">
                  <span style={{ fontSize: '14px' }}>Bought ticket: {partySize}</span>
                </div>
                <div className="icons">
                  <strong>
                    <span style={{ fontSize: '14px' }}>Total price: ${price * partySize}</span>
                  </strong>
                </div>
              </div>
            </div>
            <div>
              <Formik
                initialValues={intialValues()}
                validationSchema={validationShema()}
                onSubmit={async (fields) => {
                  await new Promise((r) => setTimeout(r, 500));
                  handleSubmit(fields);
                }}
                render={({ errors, status, touched }) => (
                  <Form id={formId}>
                    <div>
                      <div>
                        <div className="form-group">
                          <Field
                            style={{
                              display: 'block',
                              background: 'rgb(255, 255, 255)',
                              borderRadius: 10,
                              // boxShadow: 'rgb(0 0 0 / 16%) 0px 3px 6px',
                              border: 'none',
                              fontSize: 12,
                              color: '#222a2f'
                            }}
                            placeholder="First Name"
                            name="firstName"
                            type="text"
                            className={
                              'form-control' +
                              (errors.firstName && touched.firstName ? ' is-invalid' : '')
                            }
                          />
                          <ErrorMessage
                            name="firstName"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div>
                        <div className="form-group">
                          <Field
                            style={{
                              display: 'block',
                              background: 'rgb(255, 255, 255)',
                              borderRadius: 10,
                              boxShadow: 'rgb(0 0 0 / 16%) 0px 3px 6px',
                              border: 'none',
                              fontSize: 12,
                              color: '#333333'
                            }}
                            placeholder="Last Name"
                            name="lastName"
                            type="text"
                            className={
                              'form-control' +
                              (errors.lastName && touched.lastName ? ' is-invalid' : '')
                            }
                          />
                          <ErrorMessage
                            name="lastName"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                    </div>
                    <Row>
                      <Col xs="6" lg="6">
                        <div className="form-group">
                          <Field
                            style={{
                              display: 'block',
                              background: 'rgb(255, 255, 255)',
                              borderRadius: 10,
                              boxShadow: 'rgb(0 0 0 / 16%) 0px 3px 6px',
                              border: 'none',
                              fontSize: 12,
                              color: '#333333'
                            }}
                            placeholder="Phone Number"
                            name="phoneNumber"
                            type="text"
                            className={
                              'form-control' +
                              (errors.phoneNumber && touched.phoneNumber ? ' is-invalid' : '')
                            }
                          />
                          <ErrorMessage
                            name="phoneNumber"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </Col>
                      <Col xs="6" lg="6">
                        <div className="form-group">
                          <Field
                            style={{
                              display: 'block',
                              background: 'rgb(255, 255, 255)',
                              borderRadius: 10,
                              boxShadow: 'rgb(0 0 0 / 16%) 0px 3px 6px',
                              border: 'none',
                              fontSize: 12,
                              color: '#333333'
                            }}
                            placeholder="Email"
                            name="email"
                            type="text"
                            className={
                              'form-control' + (errors.email && touched.email ? ' is-invalid' : '')
                            }
                          />
                          <ErrorMessage name="email" component="div" className="invalid-feedback" />
                        </div>
                      </Col>
                    </Row>
                    <div className="form-group">
                      <Field
                        style={{
                          display: 'block',
                          background: 'rgb(255, 255, 255)',
                          borderRadius: 10,
                          boxShadow: 'rgb(0 0 0 / 16%) 0px 3px 6px',
                          border: 'none',
                          fontSize: 12,
                          '::placeholder': {
                            color: '#B3B3B3'
                          },
                          color: '#333333'
                        }}
                        placeholder="Request"
                        name="request"
                        type="text"
                        className={
                          'form-control' + (errors.request && touched.request ? ' is-invalid' : '')
                        }
                      />
                      <ErrorMessage name="request" component="div" className="invalid-feedback" />
                    </div>
                    <div
                      style={{
                        padding: 18,
                        height: 50,
                        fontSize: 16,
                        borderRadius: 10,
                        background: '#FFFFFF 0% 0% no-repeat padding-box',
                        boxShadow: 'rgb(0 0 0 / 16%) 0px 3px 6px'
                        // border: "1px solid #CCCCCC",
                      }}
                    >
                      <CardElement
                        options={{
                          hidePostalCode: true,
                          style: {
                            base: {
                              fontSize: '12px',
                              color: '#707070',
                              height: '80px',
                              '::placeholder': {
                                color: '#B3B3B3'
                              },
                              border: '1px solid #CCCCCC',
                              borderRadius: '10px'
                            },
                            invalid: {
                              color: '#9e2146'
                            }
                          }
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: 'block',
                        background: 'rgb(255, 255, 255)',
                        borderRadius: 10,
                        boxShadow: 'rgb(0 0 0 / 16%) 0px 3px 6px',
                        padding: 17,
                        margin: 'auto',
                        marginTop: 22,
                        fontSize: 12,
                        color: '#333333'
                      }}
                    >
                      <div>Cancellation Policy</div>
                      <div>
                        Prepaid experience cannot be modified or exchanged. Please call the
                        restaurant if changes are needed.
                      </div>
                    </div>
                  </Form>
                )}
              />
            </div>
          </div>
          <hr />

          {(widgetNoteTitle !== null && widgetNoteTitle !== null) ||
          (widgetNoteTitle !== '' && widgetNoteTitle !== '') ? (
            <Row>
              <Col xs="12" lg="12">
                <h2 className=""> {widgetNoteTitle} </h2>
              </Col>
              <Col xs="12" lg="12">
                <div>{newLineMessage(widgetNoteMessage)}</div>
              </Col>
            </Row>
          ) : (
            <div className="px-2">
              <div>
                <h2 className=""> What to know before you go </h2>
              </div>
              <div>
                <h3>Important dining information</h3>
                <p>
                  We have a 15 minute grace period. Please call us if you are running later than 15
                  minutes after your reservation time.
                </p>
              </div>
              <div>
                <h3>We may contact you about this</h3>
                <p>reservation, so please ensure your email and phone number are up to date...</p>
              </div>
              <div>
                <h3>A note from the restaurant</h3>
                <p>
                  If you would like a rooftop table for dining, please specify - once all tables on
                  the roof have been reserved bookings will be allocated indoors. Please call if you
                  have any concerns. All diners have the table for a maximum of 2 hours.
                </p>
              </div>
            </div>
          )}
          <div>
            <div className="text-center">
              <Button
                form={formId}
                variant="contained"
                type="submit"
                style={{}}
                className="submit text-white"
                disabled={loading}
              >
                Confirm Booking
              </Button>
            </div>
          </div>
          <div>
            <div className="text-center">
              <p className="footer">
                "Confirm Reservation" you are agreeing to the&nbsp;
                <a href="https://javascriptpros.com" rel="noopener noreferrer" target="_blank">
                  terms and conditions
                </a>{' '}
                of the{' '}
                <a href="https://javascriptpros.com" rel="noopener noreferrer" target="_blank">
                  WABI User Agreement
                </a>
              </p>
            </div>
          </div>
          <div>
            <div>
              <p className="booking_widget__powered_by">
                Powered by{' '}
                <a href="https://javascriptpros.com" rel="noopener noreferrer" target="_blank">
                  <img src={logo} alt="logo" />
                </a>
              </p>
            </div>
          </div>
        </Container>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {/* <DialogTitle id="alert-dialog-title">Ticket sold out</DialogTitle> */}
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{`${message}`}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Okay
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};
export default withStyles(styles)(Step2);
