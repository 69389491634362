import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Box, Grid, Typography, Button, Dialog, Fade, Slide } from '@material-ui/core';
// Components
import ImageDetailSlider from '../../components/utils/ImageDetailSlider';
import EventTips from '../../components/utils/EventTips';
import GoogleMap from '../../components/utils/GoogleMap';
import SimilarEventsListStyle from '../../components/utils/SimilarEventsListStyle';
import SimilarEventsPopup from '../../components/utils/SimilarEventsPopup';
import EventBookFilter from '../../components/events/BookFilter';
import ExpandableText from '../../components/common/ExpandableText';
import UserProfileIcon from '../../components/utils/UserProfileIcon';
// Utils
import _ from 'lodash';
import TicketFilter from '../events/TicketFilter';
import VisitCountFromUuid from './VisitCountFromUuid';
// CSS
import '../../assets/scss/events/eventsDisplay.scss';
import '../../assets/scss/events/detail.scss';
const EventBase = ({ eventData, businessProfile, toggleFilter, widgetBranding }) => {
  const [eventDate, setEventDate] = useState('');
  const [isExperience, setIsExperience] = useState(false);
  const [isStatus, setIsStatus] = useState(true);
  const [isCanBook, setCanBook] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedPartySize, setSelectedPartySize] = useState();
  const [selectedTime, setSelectedTime] = useState();
  const [countByName, setCountByName] = useState();
  const [similarEventsOpen, setSimilarEventsOpen] = useState(false);
  const { key } = useParams();
  const openEventBookFilterDialog = (date, partySize, time) => {
    setSelectedDate(date);
    setSelectedPartySize(partySize);
    setSelectedTime(time);
    setOpen(true);
  };
  const openTicketFilterDialog = (countByName) => {
    setCountByName(countByName);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSimilarEventsClose = () => {
    setSimilarEventsOpen(false);
  };
  useEffect(() => {
    if (_.isEmpty(eventData)) return;
    if (eventData.type === 'EXPERIENCE') setIsExperience(true);
    setIsStatus(eventData.data.status === 1 ? true : false);
    setCanBook(eventData.data.canBook === 1 ? true : false);
    if (_.isEmpty(eventData.data.startDate)) {
      setEventDate('');
      return;
    }
    const date = new Date(eventData.data.startDate.replace(' ', 'T'));
    const options = {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    };
    setEventDate(new Intl.DateTimeFormat('en-GB', options).format(date));
  }, [eventData]);
  useEffect(() => {
    if (widgetBranding.pop_up_wabi_posts == 1) setSimilarEventsOpen(true);
  }, [widgetBranding]);
  const eventTitle = isExperience ? eventData.data.exp_name : eventData.data.name;
  const sliderImages = eventData.data.files ? eventData.data.files : widgetBranding.files;
  return (
    <div
      style={{
        minHeight: '100dvh',
        fontFamily: widgetBranding.font ? widgetBranding.font : 'inherit',
        position: 'relative'
      }}
    >
      <VisitCountFromUuid eventData={eventData} />
      {!isStatus && (
        <div className="InvalidPage">
          <p>This event is currently unavailable.</p>
        </div>
      )}
      {widgetBranding?.show_logo === 1 && (
        <div className="" style={{ position: 'fixed', top: '20px', left: '20px', zIndex: '10' }}>
          <Link to={`/v2/${key}`}>
            <UserProfileIcon IconImage={widgetBranding.logo} />
          </Link>
        </div>
      )}

      <div className="detail-kv">
        <ImageDetailSlider
          eventTitle={eventTitle}
          images={sliderImages}
          widgetBranding={widgetBranding}
          photo={businessProfile.user.business_profile.profile_pic}
        />
      </div>
      <div className={`eventContent-wrapper`}>
        <div className="globalInner">
          <Box display="flex" style={{ gap: '8px', justifyContent: 'space-between' }}>
            <Grid className="event-summary">
              <Typography
                variant="body1"
                className="event-title"
                style={{ fontFamily: widgetBranding.font ? widgetBranding.font : 'inherit' }}
              >
                {isExperience ? eventData.data.exp_name : eventData.data.name}
              </Typography>
              <ExpandableText
                text={isExperience ? eventData.data.exp_description : eventData.data.description}
                maxLength={150}
                widgetBranding={widgetBranding}
              />
              {eventData.type === 'DEFAULT' && (
                <>
                  <p
                    className="mt-2"
                    style={{
                      fontFamily: widgetBranding.font ? widgetBranding.font : 'inherit'
                    }}
                  >
                    {widgetBranding.description || 'Description'}
                  </p>
                </>
              )}
              <Typography
                variant="body2"
                className="event-date"
                style={{ color: widgetBranding.accent_colour || '#fff' }}
              >
                {eventDate}
              </Typography>
            </Grid>
            <div className="bookfilter-forDesktop">
              {eventData.type === 'TICKET' && (
                <TicketFilter
                  isAside={true}
                  ticketData={eventData.data}
                  businessProfile={businessProfile}
                  openFilterDialog={openTicketFilterDialog}
                  widgetBranding={widgetBranding}
                  eventData={eventData}
                />
              )}
              {eventData.type === 'POST' && (
                <>
                  {isCanBook ? (
                    <EventBookFilter
                      key={Math.random()}
                      openFilterDialog={openEventBookFilterDialog}
                      isAside={true}
                      propTitle={isExperience ? eventData.data.exp_name : eventData.data.name}
                      propDate={selectedDate}
                      widgetBranding={widgetBranding}
                    />
                  ) : (
                    <></>
                  )}
                </>
              )}
              {eventData.type === 'EXPERIENCE' && (
                <>
                  <EventBookFilter
                    key={Math.random()}
                    openFilterDialog={openEventBookFilterDialog}
                    isAside={true}
                    propTitle={isExperience ? eventData.data.exp_name : eventData.data.name}
                    propDate={selectedDate}
                    widgetBranding={widgetBranding}
                  />
                </>
              )}
              {eventData.type === 'DEFAULT' && (
                <>
                  {isCanBook ? (
                    <EventBookFilter
                      key={Math.random()}
                      openFilterDialog={openEventBookFilterDialog}
                      isAside={true}
                      propTitle={isExperience ? eventData.data.exp_name : eventData.data.name}
                      propDate={selectedDate}
                      widgetBranding={widgetBranding}
                    />
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          </Box>
          {eventData.type === 'TICKET' && (
            <div className="bookFunction-wrapper">
              <Button
                className="functionButton"
                onClick={toggleFilter}
                variant="contained"
                style={{
                  backgroundColor: widgetBranding?.book_now_colour || '#485df9',
                  color: widgetBranding.has_button_font_colour
                    ? widgetBranding.accent_colour || '#fff'
                    : '#fff',
                  fontFamily: widgetBranding.font ? widgetBranding.font : 'inherit'
                }}
              >
                <>Buy Tickets</>
              </Button>
            </div>
          )}
          {eventData.type === 'POST' && (
            <>
              {isCanBook ? (
                <div className="bookFunction-wrapper">
                  <Button
                    className="functionButton"
                    onClick={toggleFilter}
                    variant="contained"
                    style={{
                      backgroundColor: widgetBranding?.book_now_colour || '#485df9',
                      color: widgetBranding.has_button_font_colour
                        ? widgetBranding.accent_colour || '#fff'
                        : '#fff',
                      fontFamily: widgetBranding.font ? widgetBranding.font : 'inherit'
                    }}
                  >
                    <>Book Now</>
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </>
          )}
          {eventData.type === 'DEFAULT' && (
            <>
              {isCanBook ? (
                <div className="bookFunction-wrapper">
                  <Button
                    className="functionButton"
                    onClick={toggleFilter}
                    variant="contained"
                    style={{
                      backgroundColor: widgetBranding?.book_now_colour || '#485df9',
                      color: widgetBranding.has_button_font_colour
                        ? widgetBranding.accent_colour || '#fff'
                        : '#fff',
                      fontFamily: widgetBranding.font ? widgetBranding.font : 'inherit'
                    }}
                  >
                    <>Book Now</>
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </>
          )}
          {eventData.type === 'EXPERIENCE' && (
            <div className="bookFunction-wrapper">
              <Button
                className="functionButton"
                onClick={toggleFilter}
                variant="contained"
                style={{
                  backgroundColor: widgetBranding?.book_now_colour || '#485df9',
                  color: widgetBranding.has_button_font_colour
                    ? widgetBranding.accent_colour || '#fff'
                    : '#fff',
                  fontFamily: widgetBranding.font ? widgetBranding.font : 'inherit'
                }}
              >
                <>Book Now</>
              </Button>
            </div>
          )}
          <EventTips
            startTime={eventData.data.startDate}
            businessName={businessProfile.user.business_profile.business_name}
            address={businessProfile.user.business_profile.address}
            widgetBranding={widgetBranding}
            eventData={eventData}
          />
          <Grid
            container
            className="map-wrapper"
            style={{
              borderRadius: '10px',
              overFlow: 'hidden',
              marginTop: '16px'
            }}
          >
            <GoogleMap
              businessName={businessProfile.user.business_profile.business_name}
              businessAddress={businessProfile.user.business_profile.address}
              style={{ height: '240px' }}
            />
          </Grid>
          <Slide direction="up" in={similarEventsOpen} mountOnEnter unmountOnExit>
            <div>
              <SimilarEventsPopup
                open={similarEventsOpen}
                handleClose={handleSimilarEventsClose}
                eventType={eventData.type}
                eventId={eventData.data.id}
                businessName={businessProfile.user.business_profile.business_name}
                widgetBranding={widgetBranding}
              />
            </div>
          </Slide>
          <SimilarEventsListStyle
            eventType={eventData.type}
            eventId={eventData.data.id}
            expirationDate={eventData.data.expirationDate}
            widgetBranding={widgetBranding}
          />
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <Fade timeout={1200}>
              {eventData.type === 'TICKET' ? (
                <TicketFilter
                  isDialog={true}
                  ticketData={eventData.data}
                  businessProfile={businessProfile}
                  propTicketCountByName={countByName}
                  widgetBranding={widgetBranding}
                  eventData={eventData}
                />
              ) : (
                <EventBookFilter
                  key={Math.random()}
                  isDialog={true}
                  propDate={selectedDate}
                  propPartySize={selectedPartySize}
                  propTime={selectedTime}
                  propTitle={isExperience ? eventData.data.exp_name : eventData.data.name}
                  widgetBranding={widgetBranding}
                />
              )}
            </Fade>
          </Dialog>
        </div>
      </div>
    </div>
  );
};
export default EventBase;
