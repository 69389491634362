import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';
import { FaCalendarCheck } from 'react-icons/fa';
import { FaUserGroup } from 'react-icons/fa6';
import { MdOutlineAccessTimeFilled } from 'react-icons/md';
import { FaShop } from 'react-icons/fa6';
import { FaMapMarkerAlt } from 'react-icons/fa';
import GoogleMap from '../../components/utils/GoogleMap';
import { FaBookmark } from 'react-icons/fa';

import { RiPencilFill } from 'react-icons/ri';
import { MdOutlineCancel } from 'react-icons/md';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from './../../assets/images/logo.png';
// Material-UI
import {
  Typography,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

// Styles
import '../../assets/css/booking/bookingDetails.css';

// Hooks
import useBusinessProfile from '../../hooks/@booking/useBusinessProfile';
import getMyBooking from '../../hooks/@booking/useMyBooking';
import { cancelBooking } from '../../hooks/@booking/useCancelBooking';

// Utils
import { s3Bucket } from '../../config';
import moment from 'moment';

const bookingStatuses = [
  'Confirmed', // 0
  'ALL', // 1
  'Billed', // 2
  'Unbill', // 3
  'Seated', // 4
  'Unseat', // 5
  'Finished', // 6
  'No Show', // 7
  'Cancelled', // 8
  'Seat Not WaitList', // 9
  'WaitList', // 10
  'OverTime', // 11
  'Upcoming', // 12
  'Late', // 13
  'Need Attention', // 14
  'Partially Seated', // 15
  'Unfinished', // 16
  'Pending' // 17
];

const BookingDetails = () => {
  const [status, setStatus] = useState('confirmed');
  const { key, id } = useParams();
  const { businessProfileData } = useBusinessProfile(key);
  const [myBooking, setMybooking] = useState(null);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const { businessName, businessAddress, photo } = businessProfileData;

  const getStatusChip = (status) => {
    return (
      <Chip
        label={bookingStatuses[status]}
        style={{
          backgroundColor: '#fff',
          color: status === 8 ? '#f44336' : 'color: #000',
          fontWeight: 'bold',
          fontFamily: 'Inter',
          fontSize: '18px',
          textTransform: 'capitalize'
        }}
      />
    );
  };

  const formatDate = (dateString) => {
    const date = moment(dateString);
    const formattedDate = date.format('ddd, D MMM YYYY'); // Short form of day
    return <>{formattedDate}</>;
  };
  const formatTime = (dateString) => {
    const date = moment(dateString);
    const formattedTime = date.format('h:mm a'); // Time in 12-hour format
    return <>{formattedTime}</>;
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await getMyBooking(id, key);
      if (result.status === 200) {
        setMybooking(result.data);
        setStatus(Number(result.data.status));
      }
      console.log('result:', result);
    };

    fetchData();
  }, [id, key]);
  useEffect(() => {
    console.log('myBooking:', myBooking?.guest?.request);
  }, [myBooking]);

  const handleCancelClick = () => {
    setOpenCancelDialog(true);
  };

  const handleCancel = async () => {
    try {
      const result = await cancelBooking(id, key);

      if (result.status === 200) {
        setStatus(8);
        setOpenCancelDialog(false);
      } else {
        console.error('Failed to cancel booking:', result.message);
      }
    } catch (error) {
      console.error('Error while cancelling booking:', error);
    }
  };

  const handleClose = () => {
    setOpenCancelDialog(false);
  };

  return (
    <div className="wabi-root booking-management">
      <div>
        <div className="wabi-container">
          <p className="wabi-header-ttl">Manage Reservation</p>
          <div className="wabi-header">
            <div className="wabi-title-wrapper">
              <FaShop />
              <p className="wabi-title">
                {businessName} <br />
                <span className="wabi-subtitle">{businessAddress}</span>
              </p>
            </div>
            <div className="wabi-header-status">
              <div className="business-icon rounded-lg">
                <img
                  src={s3Bucket + photo}
                  alt={businessName}
                  style={{ width: '100%', height: '100%', borderRadius: '10px' }}
                />
              </div>
              <div className="">
                <div className="check-step">
                  {status === 8 ? (
                    <MdOutlineCancel className="check-icon" style={{ color: '#f44336' }} />
                  ) : (
                    <IoIosCheckmarkCircleOutline className="check-icon" />
                  )}

                  <div className="mb-0">{getStatusChip(status)}</div>
                </div>
              </div>
            </div>
          </div>
          <p className="wabi-header-second">Booking Detail</p>

          <div>
            <div className="booking-body">
              <div>
                {/* Date and Time */}

                <div className="detail-item">
                  <FaBookmark />
                  <span className="wabi-subtitle">
                    {' '}
                    {myBooking?.guest?.first_name} {myBooking?.guest?.last_name}
                  </span>
                </div>
                <div className="detail-item">
                  <FaCalendarCheck className="icon" />
                  <span className="wabi-subtitle">{formatDate(myBooking?.booking_date)}</span>
                </div>
                <div className="detail-item">
                  <MdOutlineAccessTimeFilled className="icon" />
                  <span className="wabi-subtitle">{formatTime(myBooking?.booking_date)}</span>
                </div>
                <div className="detail-item">
                  <FaUserGroup className="icon" />
                  <span className="wabi-subtitle">{myBooking?.party_size} Guests</span>
                </div>
                <div className="detail-item">
                  <FaMapMarkerAlt className="icon" />{' '}
                  <span className="wabi-subtitle">{businessAddress}</span>
                </div>
                {myBooking?.notes !== null && (
                  <div className="detail-item">
                    <RiPencilFill className="icon" />
                    <span className="wabi-subtitle">{myBooking?.notes}</span>
                  </div>
                )}
              </div>
              <GoogleMap
                businessName={businessName}
                businessAddress={businessAddress}
                style={{ height: '160px', width: '100%', borderRadius: '10px' }}
              />
            </div>

            {/* Button Row: Cancel */}
            {status === 0 && ( // Only show if status is UNCONFIRMED
              <div style={{ marginTop: 60 }} justifyContent="center">
                <Button
                  variant="outlined"
                  style={{
                    backgroundColor: '#000',
                    color: '#fff',
                    borderColor: '#fff',
                    borderRadius: '5px',
                    padding: '10px 0',
                    width: '100%',
                    textTransform: 'capitalize',
                    fontFamily: 'Inter',
                    fontSize: '14px',

                    textAlign: 'center'
                  }}
                  onClick={handleCancelClick}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Confirmation Dialog with Black Theme */}
      <Dialog
        open={openCancelDialog}
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: '10px' // Optional: round corners
          }
        }}
      >
        <DialogTitle style={{}}>Confirm Cancellation</DialogTitle>
        <DialogContent>
          <Typography style={{}}>Would you like to cancel your reservation?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={handleCancel} color="secondary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Row>
        <Col xs lg="12" className="text-center">
          <p className="booking_widget__powered_by">
            Powered by{' '}
            <a href="https://javascriptpros.com" rel="noopener noreferrer" target="_blank">
              <img src={logo} alt="logo" />
            </a>
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default BookingDetails;
